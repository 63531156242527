import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PressArticlesPreviewCard = ({ article, className }) => {
  return (
    <article className={className}>
      <Link
        to={`/prensa/${article.slug}`}
        className="grid w-full grid-cols-1 flex-col overflow-hidden hover:text-brand-primary lg:grid-cols-2 lg:flex-row"
      >
        <GatsbyImage
          className="h-64 w-64 flex-shrink-0 rounded-md"
          layout="constrained"
          image={getImage(
            article.coverThumbnail?.localFile || article.cover?.localFile
          )}
          alt={article.cover?.alternativeText || ""}
        />
        <div className="flex w-full flex-col items-start justify-start py-4">
          <div className="text-brand-primary">{article.createdAt}</div>
          <h2 className="line-clamp-2 w-full text-5xl font-medium">
            {article.title}
          </h2>
          <h3 className="mt-4 line-clamp-3 w-full text-xl font-medium">
            {article.subtitle}
          </h3>
        </div>
      </Link>
    </article>
  )
}

PressArticlesPreviewCard.propTypes = {
  article: PropTypes.shape({
    cover: PropTypes.shape({
      alternativeText: PropTypes.string,
      localFile: PropTypes.any,
    }),
    coverThumbnail: PropTypes.shape({
      alternativeText: PropTypes.string,
      localFile: PropTypes.any,
    }),
    createdAt: PropTypes.string,
    slug: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }),
  className: PropTypes.string,
}

export const query = graphql`
  fragment PressArticlesPreviewCard on STRAPI_PRESS {
    id
    slug
    title
    subtitle
    createdAt: publishedDate(
      formatString: "[Madrid, ] DD [de] MMMM YYYY"
      locale: "ES"
    )
    description
    cover {
      mime
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77)
        }
      }
    }
    coverThumbnail {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1, height: 256)
        }
      }
    }
  }
`

export default PressArticlesPreviewCard
