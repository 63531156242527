import PropTypes from "prop-types"
import React from "react"
import PressArticlesPreviewCard from "./press-articles-preview-card"

const PressArticlesPreviewGrid = ({ articles }) => {
  return (
    <div className="mt-12 grid grid-cols-1 gap-4">
      {articles.map((article) => (
        <div key={article.id}>
          <hr className="border-t-black" />
          <PressArticlesPreviewCard
            article={article}
            className="mt-4 px-4 lg:px-20"
          />
        </div>
      ))}
    </div>
  )
}

PressArticlesPreviewGrid.propTypes = {
  articles: PropTypes.array,
}

export default PressArticlesPreviewGrid
